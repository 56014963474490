import dayjs from "dayjs";
import { useTranslation, AvailableLocaleType, useLocale } from "~/libs/i18n";
import { ModelTypes } from "../zeus/index";
import { useSearchParams } from "@remix-run/react";

import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween);

export const getTimeSectionByDateRange = (
  event: ModelTypes["Event"],
  start: number = dayjs().valueOf(),
  end: number = dayjs().valueOf(),
  searchParamDistrict?: string
): ModelTypes["Event_Sections"] | undefined => {
  const section = (event.sections ?? []).find(
    ({ startDatetime, endDatetime, toThisDay, district }) => {
      if (searchParamDistrict) {
        return searchParamDistrict && district?.value === searchParamDistrict;
      }

      const s = dayjs(startDatetime);
      const e = dayjs(toThisDay ?? endDatetime);

      return (
        dayjs(start).isBetween(s, e, "day") ||
        dayjs(start).isBefore(e) ||
        dayjs(start).isSame(e, "day")
      );
    }
  );

  return section;
};

export const getDatetimeDisplay = ({
  t,
  locale,
  section,
}: {
  section: ModelTypes["Event_Sections"];
  t: any;
  locale?: any;
}) => {
  const isSameDateTime = section?.startDatetime === section?.endDatetime;
  const isFullDay = section?.fullDay;
  const toThisDay = section?.toThisDay;
  const startDateObj = dayjs(section?.startDatetime);
  const endDateObj = dayjs(toThisDay ? toThisDay : section?.endDatetime);
  const isSameDay = startDateObj?.isSame(endDateObj, "day");
  const isSameMonth = startDateObj?.isSame(endDateObj, "month");
  const isSameYear = startDateObj?.isSame(endDateObj, "year");
  const isEn = locale === AvailableLocaleType?.en;

  const startTimeDisplay = isFullDay
    ? ""
    : startDateObj.locale(locale).format(t("time.HHmm") as string);
  const endTimeDisplay = isFullDay
    ? ""
    : dayjs(section?.endDatetime)
        .locale(locale)
        .format(t("time.HHmm") as string);

  const days = endDateObj.diff(startDateObj, "days");

  const hour =
    dayjs(dayjs(section.endDatetime).locale(locale).format()).diff(
      dayjs(section.startDatetime).locale(locale).format(),
      "hours"
    ) % 24;
  const mins =
    dayjs(dayjs(section.endDatetime).locale(locale).format()).diff(
      dayjs(section.startDatetime).locale(locale).format(),
      "minutes"
    ) % 60;

  // const hour = dayjs(endDateObj).diff(dayjs(startDateObj), "hours") % 24;
  // const mins = dayjs(endDateObj).diff(dayjs(startDateObj), "minutes") % 60;

  const displayHour = `${
    hour > 0 || hour < 24
      ? `(${days ? `${days} ${t("time.days")}${days > 1 && isEn ? `s` : ""} ` : ""}${hour} ${t("time.hour")}${hour > 1 && isEn ? `s` : ``}${
          mins > 0 ? ` ${mins} ${t("time.minutes")}` : ""
        })`
      : ""
  }`;

  let display = "";

  if (isSameDay) {
    const startDateDisplay = startDateObj
      .locale(locale)
      .format(t("time.YYYYMMDD") as string);

    if (isFullDay) {
      display = `${startDateDisplay}`;
      return display;
    }

    if (isSameDateTime) {
      display = `${startDateDisplay} ${startTimeDisplay}`;
      return display;
    }

    display = `${startDateDisplay} ${startTimeDisplay} ${startTimeDisplay ? "-" : ""} ${endTimeDisplay} ${
      hour ? displayHour : ""
    }`;
  } else if (isSameYear && isSameMonth) {
    const startDateDisplay = startDateObj
      .locale(locale)
      .format(t("time.YYYYMMDDTo") as string);
    const endDateDisplay = endDateObj
      .locale(locale)
      .format(t("time.DD") as string);
    display = `${startDateDisplay}${endDateDisplay} ${startTimeDisplay} ${startTimeDisplay ? "-" : ""} ${endTimeDisplay} ${
      hour ? displayHour : ""
    }`;
  } else if (isSameYear && !isSameMonth) {
    const startDateDisplay = startDateObj
      .locale(locale)
      .format(t("time.YYYYMMDDTo") as string);
    const endDateDisplay = endDateObj
      .locale(locale)
      .format(t("time.DR-MD") as string);
    display = `${startDateDisplay}${endDateDisplay}${
      isEn ? "" : t("time.day")
    } ${startTimeDisplay} ${isFullDay ? "" : "-"} ${endTimeDisplay} ${
      hour ? displayHour : ""
    }`;
  } else {
    const startDateDisplay = startDateObj
      .locale(locale)
      .format(t("time.YYYYMMDD") as string);
    const endDateDisplay = endDateObj
      .locale(locale)
      .format(t("time.YYYYMMDD") as string);

    display = `${startDateDisplay} - ${endDateDisplay} ${startTimeDisplay} ${
      isFullDay ? "" : "-"
    } ${endTimeDisplay} ${hour ? displayHour : ""}`;
  }

  return display;
};

export const useDatetimeDisplay = ({
  section,
}: {
  section: ModelTypes["Event_Sections"];
}) => {
  const t = useTranslation();
  const locale = useLocale();
  return getDatetimeDisplay({ t, section, locale });
};

export const useTimeSectionByDateRange = (
  event: ModelTypes["Event"],
  start: number = dayjs().valueOf(),
  end: number = dayjs().valueOf()
): ModelTypes["Event_Sections"] | undefined => {
  const [sp] = useSearchParams();
  const searchParamDistrict = sp.get("district") ?? "";
  return getTimeSectionByDateRange(event, start, end, searchParamDistrict);
};

export const notEmpty = (arr: any[]): boolean => {
  return arr && arr.length !== 0;
};

export const filterByEndDatetime = (array: any[]) => {
  const currentDate = new Date();

  return array.filter((obj) => {
    const sections = (obj?.item?.sections as any[]) || (obj?.sections as any[]);
    const hasValidEndDatetime = sections?.some((section) => {
      const endDatetime = new Date(section?.toThisDay ?? section?.endDatetime);
      return endDatetime > currentDate;
    });

    return hasValidEndDatetime;
  });
};

export const isMatchingEventRoute = (link: string) => {
  const regex = /^\/event\/([a-zA-Z0-9]+)\/([^\/]*)$/;;
  const isMatch = regex.test(link);
  let eventId = null
  if(isMatch) {
    const event = link.match(regex);
    eventId = event&&event[1] as string
  }
  return eventId
};

